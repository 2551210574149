import { useAppStore } from "~/store/app"

export default defineNuxtPlugin((app) => {
    const store = useAppStore()

    app.$router.beforeEach((to, from, next) => {
        const forceReload = store.forceReload

        // Force the page to reload when there is a new deploy
        if (forceReload) {
            console.info('🥐 🧉 new deployment: force reload')
            store.setForceReload(false)
            window.location = to.fullPath
        }

        return next()
    })
})
