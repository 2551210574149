export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
    loggedIn: false,
  }),

  actions: {
    fetch({ commit }) {
      return useAPI("/api/auth/user")
        .then(({ data }) => {
          this.user = data;
          this.loggedIn = true;
          return data;
        })
        .catch((error) => {
          this.user = null;
          this.loggedIn = false;

          return error;
        });
    },
    login({ commit, dispatch, rootState }, data) {
      return useAPI("/api/auth/login", {
        method: 'post',
        body: data
      })
        .then((loginResponse) => {
          const token = loginResponse.data.access_token;

          this.$cookies.set("AuthTkn", token, {
            expires: new Date(loginResponse.data.expires_at),
            secure: process.env.NODE_ENV === "production",
            path: `/`,
            // path: `/${rootState.country}-${rootState.locale}`,
          });
          this.$cookies.set("expires_at", loginResponse.data.expires_at);
          return dispatch("fetch");
        });
    },
    logout({ commit, rootState }) {
      commit("RESET_USER");

      this.$cookies.remove("AuthTkn", {
        path: `/`,
      });
      this.$cookies.remove("AuthTkn", {
        path: `/${rootState.country}-${rootState.locale}`,
      });

      return Promise.resolve();
    },
  },

  getters: {
    isAuthenticated(state) {
      return state.loggedIn;
    },
    loggedUser(state) {
      return state.user;
    },
  },
});