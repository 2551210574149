import { useAuthStore } from "../store/auth";

export function useAPI(url, optionsData, context = null) {
    const config = useRuntimeConfig();
    const route = useRoute();

    if (!context) {
        context = useNuxtApp();
    }

    const { $cookies } = context

    let headers = {};

    if (route.params.country) {
        const [country, locale] = route.params.country.split("-");
        if (country) {
            headers = {
                "X-Tenant": country,
                "X-Locale": locale,
            };
        }
    }

    const token = $cookies.get("AuthTkn");

    if (token) {
        headers = {
            ...headers,
            Authorization: `Bearer ${token}`,
            headers
        };
    }

    const updatedOption = {
        baseURL: config.public.baseURL,
        deep: true,
        onRequest({ request, options }) {
            // You can add global headers here
            options.headers = {
                ...options.headers,
                ...headers,
            };
        },
        onResponse({ request, response, options }) {
            // Handle the response here
            return Promise.resolve(response);
        },
        onResponseError({ request, response, options }) {
            // Handle the error here
            console.error("Fetch error: ", response.status, response.statusText);
            if (response && response.status === 401) {
                useAuthStore().logout();
            }

            return Promise.reject(response);
        },
    }

    return useFetch(url, {
        ...updatedOption,
        ...optionsData,
    })
}