export const useAppStore = defineStore("app", {
  state: () => ({
    forceReload: false,
  }),

  actions: {
    setForceReload(bool) {
      this.forceReload = bool;
    },
  },

  getters: {
    getForceReload(state) {
      return state.forceReload;
    },
  },
  persist: true,
});